import logo from './logo.svg';
import './App.css';
import { useRef, useState } from 'react';

function App() {
  const products = [
    {
      title: 'Tổ Yến Nhung Hưu',
      img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/image_5.png?v=1700956962'
    },
    {
      title: 'Hector Collagen',
      img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/image_6.png?v=1700959522'
    },
    {
      title: 'Hector Đông Trùng Hạ Thảo',
      img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/image_11.png?v=1700959602'
    },
    {
      title: 'Hector Sâm',
      img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/image_13.png?v=1700961124'
    },
    {
      title: 'Trà Túi Lọc Cà Gai Leo',
      img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/image_12.png?v=1700961165'
    },
    {
      title: 'Trà Thảo Mộc',
      img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/image_20.png?v=1700961253'
    },
  ];

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState('');

  const submitHandler = async (e) => {
    e.preventDefault();
    
    const date = new Date();
    const url = window.location.href;

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    const raw = JSON.stringify({
        email: email,
        date: date,
        url: url
      })

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
      
    await fetch("https://forms-88f3d-default-rtdb.firebaseio.com/xuanlan.json", requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
    
    setIsSubmitted(true);
  }

  return (
    <div>
      <header className="xuan--lan">
          <img className='xuan--lan--logo' src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/xuan_lan_3.png?v=1700620464' />
          <div className='xuan--lan--dia--chi'>
            <h1 className='tieu--de'>Xuân Lan Nha Trang</h1>
            <p className='dia--chi'>03 Phong Châu, Phước Hải, Nha Trang</p>
          </div>
          <div className='xuan--lan--info'>
            <a href='tel:0777132447'>
              <img className='dien--thoai' src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/image_8.png?v=1700621270' />
            </a>
            <a href='https://zalo.me/0777132447'>
              <img className='dien--thoai' src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/image_9.png?v=1700621616' />
            </a>
          </div>
      </header>

      <section className='hero'>
        <div className='nong--san'>
          <h2 className='nong--san--tieu--de'>Nông Sản An Toàn Trong Trái Tim Người Việt</h2>
          <div>
            <img className='nong--san--leaf' src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/image_2.png?v=1700649514' />
          </div>
        </div>

        <div className='hero--info'>
          <form className='hero--form' id='email-form' onSubmit={(e) => submitHandler(e)}>
            <h2>Nhận Quà Tặng</h2>
            {
              !isSubmitted && (
                <div className='hero--form--wrapper'>
                  <label htmlFor='email-input'>Nhập địa chỉ email để nhận ngay quà tặng</label>
                  <div className='hero--input--wrapper'>
                    <img className='input--icon' src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/image_10.png?v=1700724037' />
                    <input onChange={(e) => setEmail(e.target.value)} className='hero--form--input' required='yes' type='email' id='email-input' />
                  </div>
                  <button style={{marginTop: '.5rem'}} className='hero--form--btn' type='submit'>Tiếp tục</button>
                </div>
              )
            }

            {
              isSubmitted && <p>Cảm ơn bạn! Hãy kiểm tra email để biết thêm thông tin.</p>
            }
            
          </form>
          <img className='hero--img' src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/image_3.png?v=1700622843' />
        </div>
      </section>

      <section className=''>
        <div className='brands'>
          <div className='brand brand--a'>
            <img className='brand--logo' src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/image_23.png' />
          </div>
          <div className='brand brand--b'>
            <img className='brand--logo' src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/image_16.png' />
          </div>
          <div className='brand brand--c'>
            <img className='brand--logo' src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/image_17_clipdrop-background-removal.png?v=1700731515' />
          </div>
          <div className='brand brand--d'>
            <img className='brand--logo' src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/image_24_clipdrop-background-removal.png?v=1700731394' />
          </div>
        
        </div>
      </section>

      <section>
        <div className='nong--dan'>
          <div className='nong--dan--left'>
            <div className='nong--dan--box'>
              <img className='nong--dan--img' src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/image_21.png?v=1700795906' />
            </div>
          </div>
          <div className='nong--dan--right'>
            <p>"70% dân nước mình là nông dân, tức hơn 60 triệu người, đồng bào của mình cả, dù giọng bắc giọng nam nhưng đều là người Việt. Một nhóm lửa đã được thắp lên, nếu bạn có thể chung tay giữ ngọn lửa ấy, thổi bùng lên “Tình yêu nông sản Việt”, thì làm. Còn bạn nếu thờ ơ, thì cứ tiếp tục thờ ơ. Mình quay lưng cũng được, nhưng đừng chỉ trích, phê phán."</p>
            <p className='nong--dan--author'>Trích bài “Nông sản an toàn và trái tim người Việt” của Tony Buổi Sáng</p>
          </div>
        </div>
      </section>

      <section className=''>
        <div className='tieu--de--san--pham'>
          <div className='tieu--de--san--pham--left'>
            <h2>SẢN PHẨM</h2>
            <p className='mo--ta--san--pham'>Cải thiện sức khỏe, tăng cường hệ miễn dịch, giúp cơ thể khỏe mạnh</p>
          </div>
          <div className='tieu--de--san--pham--right'>
            <img className='trai--tim' src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/image_4.png?v=1700919224' />
          </div>
          <img className='nong--san--leaf' src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/image_22.png?v=1700955215' />
        </div>

        <div className='san--pham'>
          {
            products.map((product => {
              return (
                <div className='moi--san--pham'>
                  <img src={product.img} />
                </div>
              );
            }))
          }

        </div>

      </section>

      <footer className='footer'>
        <div className='footer--contact'>
            <a href='tel:0777132447'>
              <img className='' src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/image_8.png?v=1700621270' />
            </a>
            <a href='https://zalo.me/0777132447'>
              <img className='' src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/image_9.png?v=1700621616' />
            </a>
          </div>
        <div className='footer--copyright'>
          <p>© 2023, Xuân Lan Nha Trang</p>
          <p>03 Phong Châu, Phước Hải, Nha Trang</p>
        </div>
      </footer>
    </div>

  );
}

export default App;
